import React from "react";
import styled from "styled-components";

import * as Styles from "../layouts/styles-constants";
import SectionHeading from "../components/SectionHeading";
import TheBook from "../images/the-book-at-the-linq.jpg";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PageHeading = styled(SectionHeading)`
  margin: 0;
  // font-size: 3;
`;
const Image = styled.img`
  max-height: 900px;
  padding: 1rem;
`;

const Caption = styled.p`
  color: ${Styles.COLOR_WHITE};
  text-align: center;
  width: 50%;
  padding: 2rem;

  ${Styles.SCREEN_MEDIUM} {
    width: 100%;
    padding: 1rem;
  }
`;

const Link = styled.a`
  color: ${Styles.COLOR_PRIMARY};
`;

const TheBookAtTheLinq = () => (
  <PageContainer>
    <PageHeading heading={"The Book @ The Linq"} />
    <Image src={TheBook} />
    <Caption>
      Worked on this project with my good friend and lead developer Luis Muchau
      check out his work <Link href="http://muchau.com">here</Link>.
    </Caption>
    <a href="/">
      <PageHeading heading={"Back"} />
    </a>
  </PageContainer>
);

export default TheBookAtTheLinq;
